import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Button } from 'antd';
import { InteractiveLabelsVisualStore } from '../stores';
import * as _ from 'lodash';
import './DocumentPagination.less';
import PageNumberControls from './PageNumberInput/PageNumberControls';

type Props = {
    store: InteractiveLabelsVisualStore;
    defaultScale?: number;
    minScale?: number;
    maxScale?: number;
};

export const DocumentPagination: React.FC<Props> = ({ store, defaultScale = 0.5, minScale = 0.5, maxScale = 1 }) => {
    const changeScale = (scale: number) => {
        const res = _.clamp(+scale.toFixed(2), minScale, maxScale);
        store.setScale(res * 100);
    };

    return (
        <div className="alpha-pagination-container">
            <div className="alpha-pagination">
                <Observer>
                    {() => (
                        <>
                            <PageNumberControls store={store} />
                            <Button
                                size="small"
                                onClick={() => changeScale(store.scale - 0.1)}
                                type="link"
                                disabled={store.scale <= minScale}
                                className="zoom-controls"
                            >
                                <i className={`alpha-icon sm zoom-out ${store.scale <= minScale ? 'inactive' : ''}`} />
                            </Button>
                            <Button
                                size="small"
                                onClick={() => store.setScale(defaultScale * 100)}
                                type="link"
                                className="zoom-controls"
                            >
                                <i className="alpha-icon sm reset-scale" />
                            </Button>
                            <Button
                                size="small"
                                onClick={() => changeScale(store.scale + 0.1)}
                                type="link"
                                disabled={store.scale >= maxScale}
                                className="zoom-controls"
                            >
                                <i className={`alpha-icon sm zoom-in ${store.scale >= maxScale ? 'inactive' : ''}`} />
                            </Button>
                        </>
                    )}
                </Observer>
            </div>
        </div>
    );
};

export default observer(DocumentPagination);
