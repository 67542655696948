import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Button, Collapse, Form, Input, Row, Col, Divider } from 'antd';
import LayoutHeader from '../../../../components/LayoutHeader';
import { Content } from 'antd/lib/layout/layout';
import { Utils } from '../../../common/services/Utils';
import { DataCrafterTemplatesVisualStore, DataCrafterTemplateFormStore } from '../../stores';
import './CreateDataCrafterTemplate.less';
import { PackageSelectors, PackageColumnsSelectors, MLModelSelector } from '..';
import { DataCrafterTemplateScript } from '../index';
import { FileExcelOutlined, CodeOutlined } from '@ant-design/icons';

type Props = {
    store: DataCrafterTemplatesVisualStore;
    createStore: DataCrafterTemplateFormStore;
};

const CreateDataCrafterTemplate: React.FC<Props> = ({ store, createStore }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        return () => {
            createStore.reset();
        };
    }, [createStore]);

    React.useEffect(() => {
        if (store.currentProject) {
            createStore.loadPackages();
            createStore.initialize();
        }
    }, [store, store.currentProject, createStore]);

    // Initialize form with store values
    React.useEffect(() => {
        form.setFieldsValue({
            templateName: createStore.templateName,
            plannerModelId: createStore.selectedPlannerModelId,
            coderModelId: createStore.selectedCoderModelId
        });
    }, [form, createStore.templateName, createStore.selectedPlannerModelId, createStore.selectedCoderModelId]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await createStore.createTemplate(values.templateName, values.plannerModelId, values.coderModelId);
        } catch (error) {
            // Form validation failed
        }
    };

    // Handle form field changes with a single handler
    const handleFormValuesChange = (changedValues: any, allValues: any) => {
        if ('templateName' in changedValues) {
            createStore.setTemplateName(changedValues.templateName);
        }
        if ('plannerModelId' in changedValues) {
            createStore.setSelectedPlannerModelId(changedValues.plannerModelId);
        }
        if ('coderModelId' in changedValues) {
            createStore.setSelectedCoderModelId(changedValues.coderModelId);
        }
    };

    return (
        <Layout className="create-data-crafter-template" style={{ height: '100%', background: 'white' }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(store.currentProject)}
                helpMessage=""
                title="Create Data Crafter Template"
                buttons={[
                    <Button
                        key="data-crafter-templates-go-back"
                        data-id="button-go-to-list"
                        className="light"
                        size="large"
                        onClick={() => createStore.navigateToList()}
                    >
                        Go back
                    </Button>,
                    <Button
                        key="data-crafter-templates-submit"
                        data-id="button-submit"
                        type="primary"
                        size="large"
                        onClick={handleSubmit}
                        disabled={!createStore.canSubmit}
                    >
                        Create Template
                    </Button>
                ]}
            />
            <Layout>
                <Content style={{ maxHeight: 'calc(100vh - 70px)', overflowY: 'auto' }}>
                    <div style={{ padding: '24px' }}>
                        <Form
                            form={form}
                            layout="vertical"
                            className="data-crafter-template-form"
                            onValuesChange={handleFormValuesChange}
                        >
                            <Form.Item
                                name="templateName"
                                label="Template Name"
                                rules={[
                                    { required: true, message: 'Please enter a template name' },
                                    { whitespace: true, message: 'Template name cannot be empty' }
                                ]}
                            >
                                <Input placeholder="Enter template name" />
                            </Form.Item>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="plannerModelId"
                                        label="Agent Model"
                                        rules={[{ required: true, message: 'Please select a agent model' }]}
                                    >
                                        <MLModelSelector
                                            models={createStore.mlModels}
                                            isLoading={createStore.isLoadingMlModels}
                                            placeholder="Select a agent model"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="coderModelId"
                                        label="Code Generation Model"
                                        rules={[{ required: true, message: 'Please select a code generation model' }]}
                                    >
                                        <MLModelSelector
                                            models={createStore.mlModels}
                                            isLoading={createStore.isLoadingMlModels}
                                            placeholder="Select a code generation model"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Collapse defaultActiveKey={['1']} bordered={false}>
                                <Collapse.Panel
                                    header={
                                        <span>
                                            <FileExcelOutlined /> Step 1: Select Package Columns
                                        </span>
                                    }
                                    key="1"
                                >
                                    <Divider className="selectors-divider" />
                                    <PackageSelectors formStore={createStore} />
                                    <Divider className="selectors-divider" />
                                    <PackageColumnsSelectors formStore={createStore} />
                                </Collapse.Panel>
                                <Collapse.Panel
                                    header={
                                        <span>
                                            <CodeOutlined /> Step 2: Compile Template Script
                                        </span>
                                    }
                                    key="2"
                                >
                                    <DataCrafterTemplateScript store={createStore} />
                                </Collapse.Panel>
                            </Collapse>
                        </Form>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(CreateDataCrafterTemplate);
