import * as React from 'react';
import { AutoComplete, Input } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import './AutoCompleteTextArea.less';

const quoteChars = ["'", '"'];

interface AutoCompleteOption {
    label: string;
    value: string;
}

interface Props {
    value: string;
    options: AutoCompleteOption[];
    onChange: (value: string) => void;
}

const AutoCompleteTextArea: React.FC<Props> = ({ value, options, onChange }: Props) => {
    const [filteredOptions, setFilteredOptions] = React.useState<AutoCompleteOption[]>([]);
    const [optionsVisible, setOptionsVisible] = React.useState<boolean>(false);
    const [cursorCoordinates, setCursorCoordinates] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [delayedOptionsVisible, setDelayedOptionsVisible] = React.useState<boolean>(false);
    const [currentCursorPosition, setCurrentCursorPosition] = React.useState<number>(0);

    const backgroundRef = React.useRef<HTMLDivElement>(null);
    const textAreaRef = React.useRef<TextAreaRef>(null);

    const optionValues = React.useMemo(() => options.map(option => option.value), [options]);

    const getCursorCoordinates = (textarea: HTMLTextAreaElement, position: number) => {
        const div = document.createElement('div');
        const style = window.getComputedStyle(textarea);

        // Copy all styles
        for (const prop of Object.keys(style)) {
            const propertyValue = style.getPropertyValue(prop);

            if (propertyValue) {
                div.style.setProperty(prop, propertyValue);
            }
        }

        div.style.position = 'absolute';
        div.style.visibility = 'hidden';
        div.style.whiteSpace = 'pre-wrap';
        div.style.overflowWrap = 'break-word';
        div.style.overflow = 'auto';
        div.style.width = textarea.offsetWidth + 'px';
        div.style.height = textarea.offsetHeight + 'px';

        const before = textarea.value.substring(0, position);
        const after = textarea.value.substring(position);

        // Add a span where the caret is
        div.textContent = before;
        const span = document.createElement('span');
        span.textContent = after[0] || '.';
        div.appendChild(span);

        document.body.appendChild(div);
        const { offsetLeft: x, offsetTop: y } = span;
        document.body.removeChild(div);

        return { x, y };
    };

    const handleSearch = (searchValue: string) => {
        onChange(searchValue);

        const textArea = textAreaRef.current?.resizableTextArea?.textArea;

        if (!textArea) {
            return;
        }

        const cursorPosition = textArea.selectionStart ?? 0;
        const beforeCursor = searchValue.slice(0, cursorPosition);
        const lines = beforeCursor.split('\n');
        const lastLine = lines[lines.length - 1];
        const lastWord = lastLine.split(' ').pop() || '';
        const atIndex = lastWord.lastIndexOf('@');

        if (atIndex === -1) {
            setOptionsVisible(false);
            return;
        }

        setCursorCoordinates(getCursorCoordinates(textArea, cursorPosition));

        // Find the indexes of quotes within the last word value
        const quoteIndexes = lastWord.split('').reduce<number[]>((indexes, char, index) => {
            if (quoteChars.includes(char)) {
                indexes.push(index);
            }
            return indexes;
        }, []);

        const unmatchedQuotes = quoteIndexes.length % 2 !== 0;

        // Case 1: '@' is entered without quotes
        if (!unmatchedQuotes) {
            return handleUnquotedSearchCase(lastWord, atIndex);
        }

        // Case 2: '@' is entered within quotes
        handleQuotedSearchCase(lastWord, quoteIndexes, cursorPosition, atIndex);
    };

    const handleUnquotedSearchCase = (lastWord: string, atIndex: number): void => {
        const filterText = lastWord.slice(atIndex + 1).toLowerCase();
        const filtered = options.filter(option => option.label.toLowerCase().includes(filterText));
        setFilteredOptions(filtered);
        setOptionsVisible(true);
    };

    const handleQuotedSearchCase = (
        lastWord: string,
        quoteIndexes: number[],
        cursorPosition: number,
        atIndex: number
    ): void => {
        const quotePos = quoteIndexes.filter(index => index < cursorPosition).pop() ?? -1;

        if (quotePos === -1 || quotePos < atIndex) {
            setOptionsVisible(false);
            return;
        }

        const filterText = lastWord.slice(quotePos + 1, cursorPosition).toLowerCase();
        const filtered = options.filter(option => option.label.toLowerCase().includes(filterText));
        setFilteredOptions(filtered);
        setOptionsVisible(true);
    };

    const handleSelect = (selectedValue: string) => {
        const cursorPosition = textAreaRef.current?.resizableTextArea?.textArea.selectionStart ?? 0;
        const textAreaValue = textAreaRef.current?.resizableTextArea?.textArea.value ?? '';

        const beforeCursor = textAreaValue.slice(0, cursorPosition);
        const afterCursor = textAreaValue.slice(cursorPosition);

        // Find the last '@' before the cursor position
        const atIndex = beforeCursor.lastIndexOf('@');

        if (atIndex === -1) {
            return; // No '@' found, do nothing
        }

        // Replace text from '@' to the cursor position with the selected value
        const newBeforeCursor = beforeCursor.slice(0, atIndex) + selectedValue.replace(/\n/g, ' ');
        const newValue = newBeforeCursor + afterCursor;

        onChange(newValue);
        setOptionsVisible(false);
        setFilteredOptions([]);

        // Set the cursor position to the end of the inserted value
        const newCursorPosition = newBeforeCursor.length;
        setTimeout(() => {
            textAreaRef.current?.resizableTextArea?.textArea.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
    };

    const getHighlightedText = (text: string): string => {
        const escaped = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
        const regex = new RegExp(`(${optionValues.join('|')})`, 'gi');
        return escaped.replace(regex, '<span class="highlighted-label">$1</span>');
    };

    // Tiemout to prevent flickering
    React.useEffect(() => {
        setTimeout(() => setDelayedOptionsVisible(optionsVisible), 0);
    }, [optionsVisible]);

    // Return focus and set cursor position back to the text area
    React.useEffect(() => {
        if (!delayedOptionsVisible) {
            return;
        }

        textAreaRef.current?.focus();

        setTimeout(() => {
            const position = currentCursorPosition + 1;
            textAreaRef.current?.resizableTextArea?.textArea.setSelectionRange(position, position);
        }, 0);
    }, [delayedOptionsVisible, currentCursorPosition]);

    React.useEffect(() => {
        if (!optionsVisible) {
            return;
        }

        const handleDropdownClose = (event: Event) => {
            const target = event.target as HTMLElement | null;

            if (!target || target.closest('.rc-virtual-list-holder')) {
                return;
            }

            setOptionsVisible(false);
        };

        window.addEventListener('scroll', handleDropdownClose, true);

        return () => window.removeEventListener('scroll', handleDropdownClose, true);
    }, [optionsVisible]);

    return (
        <div className="auto-complete-text-area-container">
            <div
                ref={backgroundRef}
                className="highlight-background"
                dangerouslySetInnerHTML={{ __html: getHighlightedText(value) }}
            />

            <AutoComplete
                // Have to use key to prevent flickering of the dropdown
                key={cursorCoordinates.y}
                options={filteredOptions}
                open={delayedOptionsVisible}
                onSearch={handleSearch}
                onSelect={handleSelect}
                style={{ width: '100%' }}
                value={value}
                dropdownAlign={{
                    points: ['bl', 'tl'],
                    offset: [0, cursorCoordinates.y - 5]
                }}
            >
                <Input.TextArea
                    ref={textAreaRef}
                    style={{ background: 'transparent' }}
                    placeholder="Enter text"
                    autoSize={{ minRows: 1 }}
                    onBlur={() => setOptionsVisible(false)}
                    onChange={e => {
                        const newValue = e.target.value;
                        onChange(newValue);
                        handleSearch(newValue);
                    }}
                    onKeyUp={e => {
                        const textArea = e.target as HTMLTextAreaElement;
                        setCurrentCursorPosition(textArea.selectionStart);
                    }}
                    onClick={e => {
                        const textArea = e.target as HTMLTextAreaElement;
                        setCurrentCursorPosition(textArea.selectionStart);
                    }}
                />
            </AutoComplete>
        </div>
    );
};

export default AutoCompleteTextArea;
