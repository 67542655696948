import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Button, message, Spin, Tabs } from 'antd';
import { CopyOutlined, FileOutlined } from '@ant-design/icons';
import { DataCrafterTemplateFormStore } from '../../stores';
import { DataCrafterTemplateColumn } from '../../types';
import MappingRuleInput from './MappingRuleInput';
import './OutputPackageColumnsSelector.less';

type Props = {
    createStore: DataCrafterTemplateFormStore;
};

const OutputPackageColumnsSelector: React.FC<Props> = ({ createStore }) => {
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Column name copied to clipboard');
        });
    };

    const tableColumns = [
        {
            title: 'Column',
            dataIndex: 'name',
            key: 'name',
            width: 'auto',
            render: (_: string, record: DataCrafterTemplateColumn) => {
                return (
                    <div className="column-name-cell">
                        <span>{record.name}</span>
                        <Button
                            type="text"
                            icon={<CopyOutlined />}
                            onClick={() =>
                                handleCopy(`${record.worksheetName}:${record.name}:${record.headerCoordinates}`)
                            }
                            size="small"
                        />
                    </div>
                );
            }
        },
        {
            title: 'Address',
            dataIndex: 'headerCoordinates',
            key: 'headerCoordinates',
            width: 100,
            render: (headerCoordinates: string) => {
                return <span>{headerCoordinates}</span>;
            }
        },
        {
            title: 'Mapping Rule',
            dataIndex: 'mappingRule',
            key: 'mappingRule',
            render: (_: string, record: DataCrafterTemplateColumn) => (
                <div style={{ minWidth: 400 }}>
                    <MappingRuleInput createStore={createStore} templateColumn={record} />
                </div>
            )
        }
    ];

    // Group columns by worksheet
    const tabPanes = React.useMemo(() => {
        const worksheets = new Map<string, DataCrafterTemplateColumn[]>();
        createStore.outputColumns.forEach(col => {
            if (!worksheets.has(col.worksheetName)) {
                worksheets.set(col.worksheetName, []);
            }
            worksheets.get(col.worksheetName)?.push(col);
        });

        return Array.from(worksheets.entries()).map(([worksheet, columns]) => (
            <Tabs.TabPane
                key={worksheet}
                tab={
                    <>
                        <FileOutlined />
                        {worksheet}
                    </>
                }
            >
                <Table
                    className="columns-table"
                    dataSource={columns}
                    columns={tableColumns}
                    pagination={false}
                    size="middle"
                    bordered={false}
                    rowKey={record => `${record.worksheetName}-${record.name}-${record.headerCoordinates}`}
                />
            </Tabs.TabPane>
        ));
    }, [createStore.outputColumns]);

    return (
        <div className="output-package-columns-selector">
            <div className="output-package-columns-container">
                <span className="output-package-columns-label">Output Package Columns</span>
            </div>
            <Spin spinning={createStore.isLoadingOutputColumns} style={{ marginTop: 16 }}>
                {tabPanes.length > 0 && <Tabs>{tabPanes}</Tabs>}
            </Spin>
        </div>
    );
};

export default observer(OutputPackageColumnsSelector);
