import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Divider } from 'antd';
import { debounce } from 'lodash';
import { DataCrafterTemplateFormStore } from '../../stores';
import { InputPackageSelector, OutputPackageSelector } from '..';
import './PackageSelectors.less';

interface Props {
    formStore: DataCrafterTemplateFormStore;
}

const PackageSelectors: React.FC<Props> = ({ formStore }) => {
    const debouncedFetchInputColumns = React.useMemo(
        () =>
            debounce(() => {
                if (formStore.selectedInputPackages.length > 0) {
                    formStore.fetchInputColumns();
                }
            }, 500),
        [formStore]
    );

    const handleInputPackagesChange = (values: string[]) => {
        formStore.setSelectedInputPackages(values);
        debouncedFetchInputColumns();
    };

    const handleOutputPackageChange = (value: string | null) => {
        formStore.setSelectedOutputPackage(value);

        if (formStore.selectedOutputPackage) {
            formStore.fetchOutputColumns();
        }
    };

    return (
        <div className="package-selectors-container">
            <div className="input-package-selector-container">
                <Form.Item label="Input Packages">
                    <InputPackageSelector
                        packages={formStore.packages}
                        isLoadingPackages={formStore.isLoadingPackages}
                        value={formStore.selectedInputPackages}
                        onChange={handleInputPackagesChange}
                        placeholder="Select input packages"
                    />
                </Form.Item>
            </div>

            <Divider className="package-selectors-divider" type="vertical" />

            <div className="output-package-selector-container">
                <Form.Item label="Output Packages">
                    <OutputPackageSelector
                        packages={formStore.packages}
                        isLoadingPackages={formStore.isLoadingPackages}
                        value={formStore.selectedOutputPackage}
                        onChange={handleOutputPackageChange}
                        placeholder="Select output package"
                    />
                </Form.Item>
            </div>
        </div>
    );
};

export default observer(PackageSelectors);
