import * as React from 'react';
import { observer } from 'mobx-react-lite';
import type { InputNumberProps } from 'antd';
import { Button, InputNumber } from 'antd';
import './PageNumberControls.less';
import { InteractiveLabelsVisualStore } from '../../stores';
import { isNumber } from 'lodash';

interface Props {
    store: InteractiveLabelsVisualStore;
}

const PageNumberControls: React.FC<Props> = ({ store }: Props) => {
    const ref = React.useRef<HTMLInputElement>(null);

    const onChange: InputNumberProps['onChange'] = value => {
        if (isNumber(value)) {
            store.setCurrentPage(value - 1);
        }
    };

    const onPressEnter = () => {
        ref.current?.blur();
    };

    return (
        <div className="page-number-controls">
            <Button
                size="small"
                onClick={() => store.setCurrentPage(store.currentPage - 1)}
                type="link"
                disabled={store.currentPage <= 0}
            >
                <i className={`alpha-icon sm pagination-arrow-left ${store.currentPage <= 0 ? 'inactive' : ''}`} />
            </Button>
            <InputNumber
                ref={ref}
                min={1}
                max={store.totalPages}
                value={store.currentPage + 1}
                onChange={onChange}
                onPressEnter={onPressEnter}
            />
            <span>/</span>
            <span>{store.totalPages}</span>
            <Button
                size="small"
                onClick={() => store.setCurrentPage(store.currentPage + 1)}
                type="link"
                style={{ marginLeft: '0px' }}
                disabled={store.currentPage >= store.totalPages - 1}
            >
                <i
                    className={`alpha-icon sm pagination-arrow-right ${store.currentPage >= store.totalPages - 1 ? 'inactive' : ''}`}
                />
            </Button>
        </div>
    );
};

export default observer(PageNumberControls);
